
  import {defineComponent, computed } from "vue"
  import {useModelWrapper} from "@/utils/modelWrapper"
  import { getPlaceholder } from "@/components/shared/form/elements/utils"
  import jsonpointer from "jsonpointer"
  import { getForm, setElement } from "@/components/shared/form/form-provider"
  import HtmlElement from "@/components/shared/form/elements/html/HtmlElement.vue"

  interface Props {
    schema: any,
    pointer: string,
    required: boolean,
    title?: string,
    widget?: string | Function
  }

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    components: {
      HtmlElement
    },
    props: {
      schema: {
        type: Object,
        default: () => ({})
      },
      parentScope: {
        type: String,
        required: true
      },
      pointer: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        default: false
      },
      title: {
        type: String
      },
      widget: {
        type: [String, Function]
      },
      options: {
        type: Object
      }
    },
    setup(props, { emit }) {
      const placeholder = getPlaceholder(props)

      const form = getForm()

      const type = computed(() => {
        if (props.options?.type) {
          return props.options?.type
        }
        return "text"
      })

      const isNumber = computed(() => type.value === 'number' || type.value === 'integer')

      const enteredValue = computed({
        get: () => {
          const val = jsonpointer.get(form.value, props.parentScope)
          if (props.options?.inTransform && val && val != '') {
            return props.options?.inTransform(val)
          } else { return val }
        },
        set: (value) => setElement(props.parentScope, value)
      })

      return {
        placeholder,
        type,
        isNumber,
        enteredValue
      }
    }
  })
