import {computed} from "vue"

export const getPlaceholder = (props: any) => {
  return computed(() => {
    if (props.schema.examples && props.schema.examples.length > 0) {
      let holder = "Zum Beispiel "
      holder += props.schema.examples.join(", ")
      return holder
    }
    return ""
  })
}
